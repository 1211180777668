import React, { useState, useEffect, useRef } from 'react';
import "./Seed.css"
import * as Assets from "../../assets/loader"




 function Seed(props){
    


    const seeder = {
       
        "position":"absolute",
        "top": "0",
        "left": "0",
        "objectFit": "contain",
        "maxWidth": "100vw",
       
        
      
      
       
      
      }
        



    return(<div className = {"Seed"}>
        
        <img style = {seeder} src={Assets.Frame._seed}/>

    </div>)
 }


 export default Seed