
import full_transparent_close from "./elle_full_close.png"
import full_transparent_open from "./elle_full_open.png"
import combined_banner from './LandingPageCombinedBanner.png'
import _seed from "./seed_no_background.png"
import green_fade from "./green_fade.png"

export const Frame = {
    full_close: full_transparent_close,
    full_open: full_transparent_open,
    _seed: _seed,
}


export const Assets = {
    banner: combined_banner,
    fade: green_fade,
}