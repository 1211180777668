import React, { useState, useEffect, useRef } from 'react';

class Particle {
  constructor(x, y, r, height, ctx) {
    this.x = x;
    this.y = y;
    this.r = r;
    this.age = 0;
    this.dy = r;
    this.alive = false
    this.canvasHeight = height
  }

  draw(particle_context) {
    this.age += 1;
    particle_context.globalAlpha = 0.3;
    particle_context.fillStyle = "#ADF";
    particle_context.beginPath();
    particle_context.arc(this.x, this.y, this.r / 2, 0, 2 * Math.PI, false);
    particle_context.fill();
    particle_context.globalAlpha = 0.2;
    particle_context.beginPath();
    particle_context.arc(this.x, this.y, Math.abs(this.r * Math.sin(this.age / 10)), 0, 2 * Math.PI, false);
    particle_context.fill();
  }

  rise() {
    this.y -= this.dy / 2;
    this.x += (Math.round(Math.random() * 2) - 1)
  }

  reset() {
    this.alive = true
    // let r = Math.round(Math.random() * 10)
    this.y = this.canvasHeight;
  }
}


function Particles(props) {

  let contextRef = useRef(null);

  const canvasRef = useRef(null);
  // let canvas = canvasRef.current;
  let [intervalState, setIntervalState] = useState(null)

  let [particles, setParticles] = useState([]);

  let interval = 75;


  useEffect(() => {

  
    
    let canvas = canvasRef.current;
    contextRef.current = canvas.getContext('2d')


    const handleResize = e => {
      contextRef.current.canvas.height = window.innerHeight;
      contextRef.current.canvas.width = window.innerWidth;
    };

    handleResize();
    window.addEventListener("resize", handleResize);

   

  
    if (canvas) {
      console.log(canvas.getContext('2d'))
      contextRef.current = canvas.getContext('2d')
      if (contextRef.current) {
        let particle_context = contextRef.current;

        if (intervalState === null) {
          setIntervalState(setInterval(() => {
            // console.log(context)
            if (particle_context !== null) {
              if (particles.length < 1) {
                fillPool(50)
              }
              next_particles()
            } else {
              console.log('context is null')
            }
          }, interval))
        }

        const fillPool = (amount) => {
          for (let i = 0; i < amount; i++) {
            let x = Math.round(Math.random() *particle_context.canvas.offsetWidth);
            let y = particle_context.canvas.offsetHeight;
            let r = Math.round(Math.random() * 10)
            particles.push(new Particle(x, y, r, particle_context.canvas.offsetHeight));
          }

          return particles
        }



        const findFirstToDraw = (items) => {
          for (let i = 0; i < items.length; i++) {
            if (!items[i].alive) {
              return items[i]
            }
          }
        }

        const checkParticles = () => {
          particles.forEach((p, i) => {
            if (p.alive) {
              p.rise()
              p.draw(particle_context)
              if (p.y < 0) {
                p.alive = false
              }
            }
          })
        }

        const next_particles = () => {
          if (particle_context) {
            particle_context.clearRect(0, 0, particle_context.canvas.offsetWidth, particle_context.canvas.offsetHeight);
            if (Math.random() < 0.1 & particles.length > 0) {
              let p = findFirstToDraw(particles)
              if (p)
                p.reset()
            }
            checkParticles();
          }
          setParticles(particles)
        }

      }
    }
    return () => window.removeEventListener("resize", handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef, contextRef])

  return (
    <div className = {"Particles"}>
      <canvas ref={canvasRef}  />
    </div>)


}


//id = setInterval(next_particles, 100);




export default Particles;
