import React, { useRef } from 'react';
import * as Assets from "../assets/loader"
import Gradient from "../components/Gradient/Gradient"
import Particles from "../components/particles/Particles"
import Seed from "../components/Seed/Seed"


import "./landing.css"



const Landing = () => {
    const download = e => {
        console.log(e.target.href);
        fetch(e.target.href, {
          method: "GET",
          headers: {}
        })
          .then(response => {
            response.arrayBuffer().then(function(buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "image.png"); //or any other extension
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch(err => {
            console.log(err);
          });
      };

    const background = useRef(null);

    return (
        <div className="container" >
            <div className="backView">
                <div className={"gradientContainer"}>
                    <Gradient  width ={1000}/>
                </div>
                <div className = "backParticles">
                    <Particles />
                </div>
                <div className="backPicture">
                    <div className="bgImg" ref={background}>
                    </div>
                </div>
                    <div className = "frontParticles">
                        <Particles width = {1000} height = {600}/>
                    </div>
            </div>
            <div className="frontView">
                <div className="banner">
                    <img className ={"bannerImg"} src= {Assets.Assets.banner} alt="elle pine.seed The seed for the future of computing."/>
                </div>
                <div className="info">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                    molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                    numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                    optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
                    obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
                    nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
                    tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
                    quia. 
                </div>
            </div>
            <div className="seed">
                <Seed />
            </div>
            <div className="downloadContainer">
                <button className="downloadButton" onClick={e => {
                    background.current.style.backgroundImage =  `url(${Assets.Frame.full_open})`;
                    download(e);
                }}>

                </button>
            </div>
        </div>
    )
}

export default Landing;