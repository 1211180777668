import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Landing from './Landing/Landing';

const firebaseConfig = {
    apiKey: "AIzaSyD4d1b6BCt5B0BN9PPipqmutFEkL44JmZU",
    authDomain: "download-landing-deae4.firebaseapp.com",
    projectId: "download-landing-deae4",
    storageBucket: "download-landing-deae4.appspot.com",
    messagingSenderId: "558965616052",
    appId: "1:558965616052:web:ff13a546945fdddff556e1",
    measurementId: "G-Z6947KJBBN"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

function App() {
  return (
    <div>
      <Landing />
    </div>
  );
}
export default App;