import React, { useState, useRef, useEffect } from 'react';



class Gradient_Color {
	// initialize with height of color on screen
	// and one function each for red, green, and blue components
	constructor (h, rf, gf, bf){ 
		this.height = h // 0 is top of window, 1 is bottom
		this.red_func = rf;
		this.grn_func = gf;
		this.blu_func = bf;
	}

	generate_colors(n){
		this.red = this.red_func(n);
		this.grn = this.grn_func(n);
		this.blu = this.blu_func(n);
	}

	get color_string(){
		return `rgb(${this.red},${this.grn},${this.blu})`;
	}
}

export default function Gradient(props) {

  let contextRef = useRef(null);

  const canvasRef = useRef(null);

  useEffect(() => {
  
    let canvas = canvasRef.current;
    contextRef.current = canvas.getContext('2d')




    const handleResize = e => {
      contextRef.current.canvas.height = window.innerHeight;
      contextRef.current.canvas.width = window.innerWidth;
    };

    handleResize();
    window.addEventListener("resize", handleResize);

  
    if(canvas) {
     
      if(contextRef.current) {
        let context = contextRef.current;



        const next_frame = () => {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          frame_number = (frame_number + 1)%loop_length;
          
          let gradient = context.createLinearGradient(0,0,0,context.canvas.offsetWidth); 

          colors.forEach(
            n => {
              n.generate_colors(frame_number);
              gradient.addColorStop(n.height, n.color_string);
            }      
          )
      
          context.fillStyle = gradient;
          context.fillRect(0, 0, context.canvas.offsetWidth, context.canvas.offsetHeight);
        }

        if(intervalState === null) {
          setIntervalState(setInterval(() => {
            // console.log(context)
            if(context !== null) {
              next_frame()
            }
          }, interval))
        }
      }
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [canvasRef, contextRef]);

  let [intervalState, setIntervalState] = useState(null)

  // animation setup
  let frame_number 	= 0;
  const interval   	= 20; // ms between frames
  const loop_length 	= 2400; // animation frames
  


  // top, middle and bottom colors are initialized. -----------------------------------------------------
  const top_color = new Gradient_Color(
    0,
    n => {
      if (n > 1200)
        return 110 + 90 * Math.sin(2*Math.PI*(1.4*n + 700)/loop_length);
      else 
        return 20 + (85/1200)*n;
    },
    n => 100 + 100 * Math.sin(2*Math.PI*(n + 1800)/loop_length),
    n => 128 + 128 * Math.sin(2*Math.PI*(n + 1800)/loop_length),
  );
  
  const middle_color = new Gradient_Color(
    0.7,
    n => top_color.red - 40	* Math.sin(2*Math.PI*(n)/loop_length),
    n => top_color.grn - 10	* Math.sin(2*Math.PI*(n)/loop_length),
    n => top_color.blu + 20	* Math.sin(2*Math.PI*(n)/loop_length),
  )

  const bottom_color = new Gradient_Color(
    1,
    n =>  72 +  72 * Math.sin(2*Math.PI*(n + 1800)/loop_length), 
    n => 100 + 100 * Math.sin(2*Math.PI*(n + 1800)/loop_length),
    n => 128 + 128 * Math.sin(2*Math.PI*(n + 1800)/loop_length),
  );

  const colors = [top_color, middle_color, bottom_color]

  // animation loop ----------------------------------------------------------------------------------------



  


  return (
  <div className = {"Gradient"}>
    <canvas  ref={canvasRef} />
  </div>
  )
}